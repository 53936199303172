import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import _ from "lodash";
import { useRouter } from "next/router";

import config from "../../config";
import Header from "./header";
import Footer from "./footer";
import Banner from "../banner";
import { useMediaQueryWithUndefinedOnServer } from "../useMediaQueryWithUndefinedOnServer";

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;

interface LayoutProps {
  head?: {
    title: string;
    description?: string;
  };
  isBannerVisible?: boolean;
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ isBannerVisible, children }) => {
  const router = useRouter();
  const pathname = router.pathname;
  const isMobileScreen = useMediaQueryWithUndefinedOnServer({ query: "(max-width: 768px)" });

  return (
    <>
      <div id="relative flex">
        {isBannerVisible && isMobileScreen !== true && <Banner />}
        <Header />
        <div className="px-0 py-0">{children}</div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
