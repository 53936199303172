import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

// This hook will return undefined on the server side,
// and the actual value on the client side to prevent the "Hydration failed" error
export const useMediaQueryWithUndefinedOnServer = ({ query }: { query: string }) => {
  const [isClient, setIsClient] = useState(false);
  const isQueryMatch = useMediaQuery({ query });

  useEffect(() => {
    setIsClient(true); // useEffect code is only run on the client, but not the server; the purpose is to set isClient to true, when this code runs after this component is mounted. This component always returns undefined for server-side code.
  }, []);

  return isClient ? isQueryMatch : undefined;
};
